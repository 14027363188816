import { IContent } from '../content';

const Parking: IContent = {
	title: {
		sk: `Parkovanie`,
		en: `Parking`,
		de: `Parken`
	},
	/* 	description: {
		sk: 'Pracovné dni 1,5 hodiny ZDARMA. Každá ďalšia hodina len 0,50€**',
		en: 'Working days 1.5 hours FREE. Každá ďalšia hodina len 0,50€**',
		de: 'Arbeitstage 1,5 Stunden KOSTENLOS. Každá ďalšia hodina len 0,50€**'
	}, */
	content: {
		sk: `Pracovné dni 1,5 hodiny ZDARMA

    Víkendy a štátne sviatky prvých 1,5 hodín ZDARMA
    
    Každá ďalšia hodina len 1€**
    
    Poplatok za stratu parkovacieho lístka je 30 €. V prípade straty parkovacieho lístka, prosím, kontaktujte správu parkoviska +421 919 020 055 alebo navštívte Info Kiosk.`,
		en: `Working days 1.5 hours FREE

    Weekends and public holidays first 1.5 hours FREE
    
    Every additional hour only 1€**
    
    The fee for a lost parking ticket is €30. In case of a lost parking ticket, please contact the parking management at +421 919 020 055 or visit the Info Kiosk.`,
		de: `Arbeitstage 1,5 Stunden KOSTENLOS

    Wochenenden und Feiertage die ersten 1,5 Stunden KOSTENLOS
    
    Jede zusätzliche Stunde nur 1€**
    
    Die Gebühr für ein verlorenes Parkticket beträgt 30 €. Im Falle eines verlorenen Parktickets wenden Sie sich bitte an das Parkmanagement unter +421 919 020 055 oder besuchen Sie den Info-Kiosk.`
	},
	slug: {
		sk: 'parkovanie',
		en: 'parking',
		de: 'parken'
	}
};

export default Parking;
